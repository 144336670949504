
import {Component, Vue} from "vue-property-decorator";
import {apiStatistic} from "@/config/api";
import MainBanner from "@/blocks/main-banner/main-banner.vue";
import InfoBlock from "@/blocks/info-block/info-block.vue";

@Component({
    components: {
        MainBanner,
        InfoBlock
    },
    data() {
        return {
            disclose: false
        };
    }
})

export default class Index extends Vue {

  statistics: any = {
      drugs_count: 0,
      medicinal_products: 0,
      last_update: "",
  }

  async created() {
      apiStatistic((response: { data: any; }) => this.statistics = response.data);
  }

}
